import { Box, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <Box
      display={"flex"}
      gap={4}
      flexDirection={"column"}
      flexWrap={"wrap"}
      style={{ paddingLeft: "2%", paddingRight: "2%", marginBottom: "0.5%" }}
      // justifyContent={"space-between"}
    >
      {/* <Text fontFamily={"Times New Roman"}>© An Absolutemens.com Fashion Services Private Limited Brand.</Text> */}
      <Box
        display={"flex"}
        flexDirection={["column", "column", "row", "row"]}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} gap={10} justifyContent={"center"} marginBottom={[4,4,0,0]}>
          <NavLink to="/terms">
            <Text
              color={"#0856C0"}
              fontFamily={"Times New Roman"}
              _hover={{ textDecoration: "underline" }}
            >
              Terms and Condition
            </Text>
          </NavLink>
          <Text color={"#3FA2F6"}>|</Text>
          <NavLink to="/privacy">
            <Text
              color={"#0856C0"}
              fontFamily={"Times New Roman"}
              _hover={{ textDecoration: "underline" }}
            >
              Privacy Policy
            </Text>
          </NavLink>
        </Box>
        <Box
          display={"flex"}
          gap={6}
          justifyContent={"center"}
          marginTop={"-1%"}
        >
          <Link style={{ marginTop: "-0.5%" }}>
            <Image
              src="assets/instagram.svg"
              height={["30px", "30px", "40px", "40px"]}
              width={["30px", "30px", "40px", "40px"]}
            ></Image>
          </Link>
          <Link style={{ marginTop: "-1%" }}>
            <Image
              src="assets/facebook.svg"
              height={["30px", "30px", "40px", "40px"]}
              width={["30px", "30px", "40px", "40px"]}
            ></Image>
          </Link>
          <Link style={{ marginTop: "-1%" }}>
            <Image
              src="assets/youtube.svg"
              height={["30px", "30px", "40px", "40px"]}
              width={["30px", "30px", "40px", "40px"]}
            ></Image>
          </Link>
          <Link style={{ marginTop: "-1%" }}>
            <Image
              src="assets/linkedin.svg"
              height={["30px", "30px", "40px", "40px"]}
              width={["30px", "30px", "40px", "40px"]}
            ></Image>
          </Link>
          <Link style={{ marginTop: "-1%" }}>
            <Image
              src="assets/mail.svg"
              height={["30px", "30px", "40px", "40px"]}
              width={["30px", "30px", "40px", "40px"]}
            ></Image>
          </Link>
        </Box>
      </Box>
      <Box justifyContent={"center"} alignItems={"center"}>
        <Text fontFamily={"Times New Roman"} textAlign={"center"}>
          © An Absolutemens.com Fashion Services Private Limited Brand.
        </Text>
      </Box>
    </Box>
  );
}
