import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import BlogFirst from "./pages/BlogFirst";
import BlogSecond from "./pages/BlogSecond";
import Blogs from "./pages/Blogs";
import Features from "./pages/Features";
import ContactPage from "./pages/ContactPage";
import LoginPage from "./pages/LoginPage";
import MySidebar from "./common/admin/Sidebar";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage></HomePage>}></Route>
        <Route path="/about" element={<AboutPage></AboutPage>}></Route>
        <Route path="/terms" element={<Terms></Terms>}></Route>
        <Route path="/privacy" element={<Privacy></Privacy>}></Route>
        <Route path="/blogsfirst" element={<BlogFirst></BlogFirst>}></Route>
        <Route path="/blogssecond" element={<BlogSecond></BlogSecond>}></Route>
        <Route path="/blog" element={<Blogs></Blogs>}></Route>
        <Route path="/features" element={<Features></Features>}></Route>
        <Route path="/contact" element={<ContactPage></ContactPage>}></Route>
        <Route path="/admin/login" element={<LoginPage></LoginPage>}></Route>
        <Route path="/admin/contacts" element={<MySidebar></MySidebar>}></Route>
      </Routes>
    </>
  );
}

export default App;
