import {
  Flex,
  Box,
  Heading,
  Text,
  UnorderedList,
  OrderedList,
  ListItem,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import useWhatsAppChat from '../common/useWhatsAppChat'
export default function BlogSecond() {
  useWhatsAppChat();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Flex flexDirection={"column"} minHeight="100vh">
      <Box padding="4%">
        <Box
          padding="15px 30px"
          fontSize="28px"
          textAlign="center"
          //   backgroundColor="rgba(255, 255, 255, 0.425)"
          marginBottom="20px"
        >
          <Heading as="h2" size="lg" fontFamily={"Times New Roman"}>
            Maximizing Efficiency with SURB’s Order and Inventory Management and
            much more…
          </Heading>
        </Box>

        <Box
          padding="15px 30px"
          marginTop="10px"
          fontSize="20px"
          //   backgroundColor="rgba(255, 255, 255, 0.557)"
        >
          <Text fontFamily={"Times New Roman"} >
            Effective order and inventory management is crucial for the success
            of any e-commerce business. SURB, with its advanced tools, helps you
            streamline these processes, ensuring smooth operations and satisfied
            customers. In this blog, we’ll explore how you can maximize
            efficiency using SURB’s order and inventory management features.
          </Text>
        </Box>

        <VStack spacing={6} align="stretch">
          <Box
            padding="15px 30px"
            marginTop="10px"
            fontSize="20px"
            // backgroundColor="rgba(255, 255, 255, 0.557)"
          >
            <OrderedList spacing={6} fontFamily={"Times New Roman"} >
              {/* Efficient Order Processing */}
              <ListItem fontWeight={'bold'} className="title">Efficient Order Processing</ListItem>
              <Text mb={4} >
                <strong>Benefit: Timely and Accurate Order Fulfilment</strong>
              </Text>
              <Text>
                SURB automates the entire order processing workflow, from order
                confirmation to shipping. This automation reduces the chances of
                human error and ensures that orders are fulfilled accurately and
                promptly.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Order Confirmation:</strong> As soon as an order is
                  placed, SURB automatically sends a confirmation email to the
                  customer, reducing manual work.
                </ListItem>
                <ListItem>
                  <strong>Automated Shipping Labels:</strong> Generate shipping
                  labels automatically, saving time and reducing the risk of
                  errors.
                </ListItem>
                <ListItem>
                  <strong>Order Tracking:</strong> Provide customers with
                  real-time tracking information, enhancing their shopping
                  experience and building trust.
                </ListItem>
              </UnorderedList>

              {/* Real-Time Inventory Management */}
              <ListItem className="title" fontWeight={'bold'}>
                Real-Time Inventory Management
              </ListItem>
              <Text mb={4}>
                <strong>Benefit: Avoid Stockouts and Overselling</strong>
              </Text>
              <Text>
                SURB’s real-time inventory management ensures that your stock
                levels are always up-to-date. This feature is crucial for
                maintaining a balance between supply and demand.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Stock Level Monitoring:</strong> Keep track of your
                  inventory levels in real-time, ensuring you always know what’s
                  in stock.
                </ListItem>
                <ListItem>
                  <strong>Low Stock Alerts:</strong> Receive notifications when
                  stock levels are low, allowing you to reorder in time and
                  avoid stockouts.
                </ListItem>
                <ListItem>
                  <strong>Automatic Stock Updates:</strong> When an order is
                  placed, SURB automatically updates the inventory, preventing
                  overselling.
                </ListItem>
              </UnorderedList>

              {/* Centralized Inventory Control */}
              <ListItem className="title" fontWeight={'bold'}>
                Centralized Inventory Control
              </ListItem>
              <Text mb={4}>
                <strong>Benefit: Simplify Multi-Channel Selling</strong>
              </Text>
              <Text>
                If you’re selling on multiple channels (e.g., your online store,
                marketplaces, and physical stores), SURB’s centralized inventory
                control ensures that all your sales channels are synchronized.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Unified Dashboard:</strong> Manage inventory across
                  all channels from a single dashboard, simplifying operations.
                </ListItem>
                <ListItem>
                  <strong>Consistent Stock Levels:</strong> Ensure that stock
                  levels are consistent across all sales channels, reducing the
                  risk of overselling or underselling.
                </ListItem>
              </UnorderedList>

              {/* Advanced Reporting and Analytics */}
              <ListItem className="title" fontWeight={'bold'}>
                Advanced Reporting and Analytics
              </ListItem>
              <Text mb={4}>
                <strong>Benefit: Data-Driven Decision Making</strong>
              </Text>
              <Text>
                SURB provides detailed reports and analytics on your inventory
                and order management. These insights help you make informed
                decisions to optimize your operations.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Sales Reports:</strong> Analyze sales data to identify
                  trends and make strategic decisions about inventory stocking.
                </ListItem>
                <ListItem>
                  <strong>Inventory Reports:</strong> Understand which products
                  are selling well and which ones aren’t, helping you adjust
                  your inventory accordingly.
                </ListItem>
                <ListItem>
                  <strong>Order Reports:</strong> Track order fulfilment
                  performance to identify areas for improvement.
                </ListItem>
              </UnorderedList>

              {/* Supplier Management */}
              <ListItem fontWeight={'bold'} className="title">Supplier Management</ListItem>
              <Text mb={4}>
                <strong>Benefit: Streamline Reordering Process</strong>
              </Text>
              <Text>
                SURB’s supplier management feature allows you to manage your
                suppliers and streamline the reordering process.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Supplier Database:</strong> Maintain a database of all
                  your suppliers, including contact details and product
                  information.
                </ListItem>
                <ListItem>
                  <strong>Automated Reordering:</strong> Set up automated
                  reordering rules based on stock levels, ensuring you never run
                  out of essential products.
                </ListItem>
                <ListItem>
                  <strong>Supplier Performance Tracking:</strong> Track supplier
                  performance to ensure timely and quality deliveries.
                </ListItem>
              </UnorderedList>

              {/* Batch Processing and Bulk Updates */}
              <ListItem className="title" fontWeight={'bold'}>
                Batch Processing and Bulk Updates
              </ListItem>
              <Text mb={4}>
                <strong>Benefit: Save Time on Routine Tasks</strong>
              </Text>
              <Text>
                SURB allows you to perform batch processing and bulk updates,
                saving time on routine inventory management tasks.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Bulk Inventory Updates:</strong> Update inventory
                  levels for multiple products at once, saving time and reducing
                  manual work.
                </ListItem>
                <ListItem>
                  <strong>Batch Order Processing:</strong> Process multiple
                  orders in a batch, speeding up the order fulfilment process.
                </ListItem>
              </UnorderedList>
            </OrderedList>
          </Box>

          <Box
            padding="15px 30px"
            marginTop="10px"
            fontSize="20px"
            // backgroundColor="rgba(255, 255, 255, 0.557)"
          >
            <Text as="strong" display="block" fontFamily={"Times New Roman"}  mb={4}>
              Conclusion
            </Text>
            <Text fontFamily={"Times New Roman"} >
              Maximizing efficiency in order and inventory management is
              essential for the success of your e-commerce business. SURB’s
              advanced features provide the tools you need to streamline these
              processes, reduce errors, and ensure timely and accurate order
              fulfilment. By leveraging SURB’s capabilities, you can focus on
              growing your business and providing an exceptional shopping
              experience for your customers.
            </Text>
          </Box>
        </VStack>
      </Box>
    </Flex>
  );
}
