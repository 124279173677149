import { useEffect } from 'react';

const useWhatsAppChat = () => {
  useEffect(() => {
    // Define the script URL and options
    const scriptUrl = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?47195';
    const options = {
      "enabled": true,
      "chatButtonSetting": {
        "backgroundColor": "#00e785",
        "borderRadius": "25",
        "marginLeft": "0",
        "marginRight": "20",
        "marginBottom": "80",
        "ctaIconWATI": false,
        "position": "right"
      },
      "brandSetting": {
        "brandName": "Wati",
        "brandSubTitle": "undefined",
        "brandImg": "https://www.wati.io/wp-content/uploads/2023/04/Wati-logo.svg",
        "welcomeText": "Hi there!\nHow can I help you?",
        "messageText": "Hello, %0A I have a question about surb",
        "backgroundColor": "#00e785",
        "ctaText": "Chat with us",
        "borderRadius": "25",
        "autoShow": false,
        "phoneNumber": "918792229974"
      }
    };

    // Create and insert the script tag
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = scriptUrl;
    script.onload = () => {
      // Ensure the function is available before calling it
      if (window.CreateWhatsappChatWidget) {
        window.CreateWhatsappChatWidget(options);
      }
    };

    // Append the script to the document
    document.body.appendChild(script);

    // Cleanup function to remove the script
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once when the component mounts
};

export default useWhatsAppChat;
