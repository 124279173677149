import { Box, Flex, Heading, Text, OrderedList, UnorderedList, ListItem, Link } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import useWhatsAppChat from '../common/useWhatsAppChat'
export default function Privacy() {
    useWhatsAppChat();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Flex flexDirection={"column"} minHeight="100vh">
    <Box padding="6%">
      <Heading as="h2" size="lg" mb={4} fontFamily={"Times New Roman"}>Privacy Policy</Heading>
      <OrderedList spacing={4} fontFamily={"Times New Roman"}>
        
        {/* Introduction */}
        <ListItem fontSize="lg" fontWeight="bold">
          Introduction
        </ListItem>
        <Text mb={4}>
          SURB values your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your information when you use our software platform and services.
        </Text>
        
        {/* Information We Collect */}
        <ListItem fontSize="lg" fontWeight="bold">
          Information We Collect
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>Personal Information:</strong> We collect personal information that you provide to us, such as your name, email address, phone number, and payment information.</ListItem>
          <ListItem><strong>Usage Data:</strong> We collect information about your use of SURB, including your IP address, browser type, and activity on our platform.</ListItem>
          <ListItem><strong>Cookies:</strong> We use cookies and similar technologies to collect information about your interactions with our website and services.</ListItem>
        </UnorderedList>

        {/* How We Use Your Information */}
        <ListItem fontSize="lg" fontWeight="bold">
          How We Use Your Information
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>To Provide Services:</strong> We use your information to provide, maintain, and improve our services, including processing transactions and managing accounts.</ListItem>
          <ListItem><strong>Communication:</strong> We use your information to communicate with you, respond to your inquiries, and send you updates about our services.</ListItem>
          <ListItem><strong>Marketing:</strong> With your consent, we may use your information to send you promotional materials and offers related to SURB.</ListItem>
          <ListItem><strong>Analytics:</strong> We use analytics tools to understand how our platform is used and to improve our services.</ListItem>
        </UnorderedList>

        {/* How We Share Your Information */}
        <ListItem fontSize="lg" fontWeight="bold">
          How We Share Your Information
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our platform and providing our services.</ListItem>
          <ListItem><strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to a valid request from law enforcement or government authorities.</ListItem>
          <ListItem><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.</ListItem>
        </UnorderedList>

        {/* Data Security */}
        <ListItem fontSize="lg" fontWeight="bold">
          Data Security
        </ListItem>
        <Text mb={4}>
          We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or misuse. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </Text>

        {/* Your Rights */}
        <ListItem fontSize="lg" fontWeight="bold">
          Your Rights
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>Access:</strong> You have the right to access the personal information we hold about you and to request a copy of it.</ListItem>
          <ListItem><strong>Correction:</strong> You have the right to request that we correct any inaccurate or incomplete information.</ListItem>
          <ListItem><strong>Deletion:</strong> You have the right to request that we delete your personal information, subject to certain exceptions.</ListItem>
          <ListItem><strong>Opt-Out:</strong> You can opt out of receiving marketing communications from us at any time.</ListItem>
        </UnorderedList>

        {/* Third-Party Links */}
        <ListItem fontSize="lg" fontWeight="bold">
          Third-Party Links
        </ListItem>
        <Text mb={4}>
          Our platform may contain links to third-party websites. This Privacy Policy does not apply to those websites, and we are not responsible for their privacy practices. We encourage you to review the privacy policies of those third-party sites.
        </Text>

        {/* Children’s Privacy */}
        <ListItem fontSize="lg" fontWeight="bold">
          Children’s Privacy
        </ListItem>
        <Text mb={4}>
          SURB is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child under 18, we will take steps to delete it.
        </Text>

        {/* Changes to This Privacy Policy */}
        <ListItem fontSize="lg" fontWeight="bold">
          Changes to This Privacy Policy
        </ListItem>
        <Text mb={4}>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on our website. Your continued use of SURB after the changes become effective constitutes your acceptance of the revised Privacy Policy.
        </Text>

        {/* Contact Us */}
        <ListItem fontSize="lg" fontWeight="bold">
          Contact Us
        </ListItem>
        <Text>
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <Link href="mailto:info@absolutemens.com" color="blue.500">info@absolutemens.com</Link>.
        </Text>
      </OrderedList>
    </Box>
    </Flex>
  );
}
