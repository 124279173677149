import {
  Flex,
  Box,
  Heading,
  Text,
  UnorderedList,
  OrderedList,
  ListItem,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import useWhatsAppChat from '../common/useWhatsAppChat'

export default function BlogFirst() {
  useWhatsAppChat();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Flex flexDirection={"column"} minHeight="100vh">
      <Box padding="4%">
        <Box
          padding="15px 30px"
          fontSize="28px"
          textAlign="center"
          marginBottom="20px"
        >
          <Heading as="h2" size="lg" fontFamily={"Times New Roman"} >
            Top Features of SURB: Become an Aggregator and Start a Marketplace
            Business
          </Heading>
        </Box>

        <Box padding="15px 30px" marginTop="10px" fontSize="20px">
          <Text fontFamily={"Times New Roman"} >
            In today’s fast-paced digital world, businesses need robust tools to
            manage their online stores efficiently. SURB, a comprehensive
            e-commerce and marketplace software, offers a plethora of features
            designed to streamline operations, enhance customer experience, and
            drive growth. Here’s a detailed look at the top features of SURB and
            how they can benefit your business.
          </Text>
        </Box>

        <VStack spacing={6} align="stretch">
          <Box padding="15px 30px" marginTop="10px" fontSize="20px">
            <OrderedList spacing={6} fontFamily={"Times New Roman"} >
              {/* Multi-Vendor Support */}
              <ListItem className="title" fontWeight={'bold'}>Multi-Vendor Support</ListItem>
              <Text mb={4}>
                <strong>Benefit: Expand Your Marketplace</strong>
              </Text>
              <Text>
                SURB supports multi-vendor functionality, allowing you to host
                multiple sellers on your platform. This feature is particularly
                beneficial for businesses looking to expand their product range
                without managing all inventory in-house. Vendors can manage
                their products, prices, and stock levels, while you oversee the
                entire marketplace.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Attract More Customers:</strong> With a diverse range
                  of products from various vendors, your marketplace can cater
                  to a wider audience, attracting more customers.
                </ListItem>
                <ListItem>
                  <strong>Revenue Sharing:</strong> Earn a commission on each
                  sale vendors make, providing an additional revenue stream for
                  your business.
                </ListItem>
              </UnorderedList>

              {/* Seamless Payment Integration */}
              <ListItem className="title" fontWeight={'bold'}>
                Seamless Payment Integration
              </ListItem>
              <Text mb={4}>
                <strong>Benefit: Simplify Transactions</strong>
              </Text>
              <Text>
                SURB integrates with popular payment gateways like PhonePe,
                ensuring secure and hassle-free transactions for your customers.
                This feature not only provides convenience but also builds trust
                with your audience.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Enhanced Security:</strong> With secure payment
                  options, customers are more likely to trust your platform with
                  their financial information.
                </ListItem>
                <ListItem>
                  <strong>Faster Transactions:</strong> Streamlined payment
                  processes lead to quicker transactions, improving the overall
                  customer experience.
                </ListItem>
              </UnorderedList>

              {/* Comprehensive Dashboard */}
              <ListItem className="title" fontWeight={'bold'}>Comprehensive Dashboard</ListItem>
              <Text mb={4}>
                <strong>Benefit: Real-Time Business Monitoring</strong>
              </Text>
              <Text>
                The SURB dashboard provides a bird’s-eye view of your business
                operations. From sales metrics to inventory levels, you can
                monitor everything in real time.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Informed Decision Making:</strong> Access to real-time
                  data allows you to make informed decisions quickly, optimizing
                  your business strategies.
                </ListItem>
                <ListItem>
                  <strong>Performance Tracking:</strong> Track key performance
                  indicators (KPIs) to understand what’s working and what needs
                  improvement.
                </ListItem>
              </UnorderedList>

              {/* Advanced Order and Inventory Management */}
              <ListItem className="title" fontWeight={'bold'}>
                Advanced Order and Inventory Management
              </ListItem>
              <Text mb={4}>
                <strong>Benefit: Streamline Operations</strong>
              </Text>
              <Text>
                SURB’s order and inventory management features are designed to
                keep your operations running smoothly. Automate order
                processing, manage stock levels, and reduce the risk of
                overselling.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Efficient Order Processing:</strong> Automate the
                  entire order fulfillment process, from order confirmation to
                  shipping, ensuring timely deliveries.
                </ListItem>
                <ListItem>
                  <strong>Inventory Alerts:</strong> Receive notifications when
                  stock levels are low, allowing you to restock in time and
                  avoid missed sales opportunities.
                </ListItem>
              </UnorderedList>

              {/* Customization Options */}
              <ListItem fontWeight={'bold'} className="title">Customization Options</ListItem>
              <Text mb={4}>
                <strong>Benefit: Reflect Your Brand Identity</strong>
              </Text>
              <Text>
                With SURB, you can customize your online store to match your
                brand’s identity. Choose from various templates, customize
                layouts, and add your brand colors and logos.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Brand Consistency:</strong> Maintain a consistent
                  brand image across all touchpoints, enhancing brand
                  recognition.
                </ListItem>
                <ListItem>
                  <strong>Improved User Experience:</strong> A well-designed,
                  visually appealing store can enhance the user experience,
                  leading to higher customer satisfaction and loyalty.
                </ListItem>
              </UnorderedList>

              {/* Marketing Tools */}
              <ListItem className="title" fontWeight={'bold'}>Marketing Tools</ListItem>
              <Text mb={4}>
                <strong>Benefit: Drive Traffic and Sales</strong>
              </Text>
              <Text>
                SURB offers a range of marketing tools to help you attract,
                engage, and retain customers. From email marketing to discount
                codes, leverage these tools to boost your sales.
              </Text>
              <UnorderedList spacing={2}>
                <ListItem>
                  <strong>Targeted Campaigns:</strong> Use email marketing to
                  send personalized offers and updates to your customers,
                  increasing engagement.
                </ListItem>
                <ListItem>
                  <strong>Promotions and Discounts:</strong> Create discount
                  codes and promotional offers to attract new customers and
                  encourage repeat purchases.
                </ListItem>
              </UnorderedList>
            </OrderedList>
          </Box>

          <Box padding="15px 30px" marginTop="10px" fontSize="20px">
            <Text fontFamily={"Times New Roman"}  as="strong" display="block"  mb={4}>
              Conclusion
            </Text>
            <Text fontFamily={"Times New Roman"} >
              SURB’s top features are designed to provide a seamless and
              efficient e-commerce experience. By leveraging these features, you
              can enhance your business operations, improve customer
              satisfaction, and drive growth. Whether you’re a small business or
              a large enterprise, SURB has the tools you need to succeed in the
              competitive e-commerce landscape.
            </Text>
          </Box>
        </VStack>
      </Box>
    </Flex>
  );
}
