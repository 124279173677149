import React from "react";
import Header from "../common/Header";
import { Box, Flex, Text, Image, Link } from "@chakra-ui/react";
import Footer from "../common/Footer";
import ContactForm from "../common/ContactForm";
import useWhatsAppChat from '../common/useWhatsAppChat'

export default function ContactPage() {
  useWhatsAppChat();
  return (
    <Flex flexDirection={"column"} minHeight="100vh">
      <Header></Header>
      <Box
        display={"flex"}
        flexDirection={["column", "column", "column", "row"]}
        paddingLeft={["6%", "14%", "16%", "4%"]}
        style={{ marginTop: "4%", paddingRight: "4%" }}
        flex="1"
        gap={6}
        justifyContent={"center"}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={["100%", "80%", "80%", "60%"]}
          gap={[3, 4, 6, 8]}
        >
          <Box display={"flex"} gap={6}>
            <Image
              src="/assets/addressicon.svg"
              height={["50px", "60px", "80px", "100px"]}
              width={"100px"}
            ></Image>
            <Box display={"flex"} flexDirection={"column"}>
              <Text
                as={"b"}
                fontSize={["lg", "xl", "2xl", "4xl"]}
                fontFamily={"Times New Roman"}
              >
                Our Address:
              </Text>

              <Text as={"b"} fontSize={["sm", "lg", "xl", "2xl"]}>
                No 102 1st Floor BVR Lake Front,Veerannapalya, Main Road
                Nagavara, Bengaluru, Karnataka, 560045. GST IN-29AAVCA8849F1ZU
              </Text>
            </Box>
          </Box>
          <Box display={"flex"} gap={6} alignItems={"center"}>
            <Image
              src="/assets/mailicon.svg"
              height={["30px", "30px", "30px", "50px"]}
              width={["30px", "30px", "30px", "50px"]}
            ></Image>
            <Box display={"flex"} flexDirection={"column"}>
              <Link>
                <Text
                  as={"b"}
                  fontSize={["md", "lg", "xl", "2xl"]}
                  textDecoration={"underline"}
                >
                  info@absolutemens.com
                </Text>
              </Link>
            </Box>
          </Box>
          <Box display={"flex"} gap={6} paddingLeft={1} alignItems={"center"}>
            <Image
              src="/assets/phoneicon.svg"
              height={["30px", "30px", "30px", "50px"]}
              width={["30px", "30px", "30px", "50px"]}
            ></Image>
            <Box display={"flex"} flexDirection={"column"}>
              <Link>
                <Text
                  as={"b"}
                  fontSize={["md", "lg", "xl", "2xl"]}
                  textDecoration={"underline"}
                >
                 8147137274
                </Text>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box width={["100%", "80%", "80%", "40%"]} marginBottom={6}>
          <ContactForm></ContactForm>
        </Box>
      </Box>
      <Footer></Footer>
    </Flex>
  );
}
