import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useWhatsAppChat from '../common/useWhatsAppChat'
export default function HomePage() {
  const navigate = useNavigate();
  
  useWhatsAppChat();
  return (
    <Flex flexDirection={"column"} minHeight="100vh">
      <Header></Header>
      <Box display={"flex"} flexDirection={"column"} flex="1">
        <Box
          style={{ marginTop: "10%", marginLeft: "4%" }}
          display={"flex"}
          flexDirection={["column", "column", "column", "row"]}
        >
          <Box
            display={"flex"}
            fontFamily={"Times New Roman"}
            flexDirection={"column"}
          >
            <Text fontSize={"5xl"}>
              Scale Your Business Online With <b>SURB</b>
            </Text>
            <Box
              display={"flex"}
              gap={3}
              justifyContent={"center"}
              marginTop={4}
            >
              <Button
                color={"white"}
                bgColor={"#0856C0"}
                paddingX={7}
                colorScheme="#073a81"
                fontFamily={"Times New Roman"}
                sx={{
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
                borderRadius={"50px"}
                onClick={() => {
                  navigate("/features");
                }}
              >
                Know More
              </Button>
              <Button
                color={"white"}
                bgColor={"#0856C0"}
                borderRadius={"50px"}
                colorScheme="#073a81"
                fontFamily={"Times New Roman"}
                sx={{
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Request Demo
              </Button>
            </Box>
          </Box>
          <Box
            style={{ marginTop: "-5%" }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Image
              src="./assets/GroupERP.png"
              height={"300px"}
              width={"200px"}
              alt="ERP"
            ></Image>
          </Box>
        </Box>
        <Box
          display={"flex"}
          gap={"10%"}
          justifyContent={"center"}
          flexWrap={"wrap"}
          marginBottom={"10%"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
            cursor={"pointer"}
          >
            <Image
              src="./assets/categoriesimg.png"
              alt="ERP"
              height={"150px"}
              width={"150px"}
            ></Image>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
            cursor={"pointer"}
          >
            <Image
              src="./assets/pay.png"
              alt="ERP"
              height={"150px"}
              width={"100px"}
            ></Image>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
            cursor={"pointer"}
          >
            <Image
              src="./assets/cartimg.svg"
              alt="ERP"
              height={"200px"}
              width={"150px"}
            ></Image>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.1)",
              },
            }}
            cursor={"pointer"}
          >
            <Image
              src="./assets/phonecartimg.svg"
              alt="ERP"
              height={"150px"}
              width={"150px"}
            ></Image>
          </Box>
        </Box>
      </Box>
      <Footer></Footer>
    </Flex>
  );
}
