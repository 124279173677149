import { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Switch,
  Image,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AdminContact from "../../pages/AdminContact";

export default function MySidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const [rtl, setRTL] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [isLessThanMd] = useMediaQuery("(max-width: 48em)");
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setCollapsed(false);
  }, [isLessThanMd]);



  const handlelogout = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    } else {
      localStorage.removeItem("token");
      navigate("/admin/login");
    }
  };


  
  return (
    <Box width={"100%"} position="fixed" top={0} zIndex={99}>
      <Box
        minW={"100%"}
        display={"flex"}
        bgColor={"#0b223a"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={4}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={3}
          alignItems={"center"}
        >
          <NavLink to={"/"}>
            <Text
              color={"white"}
              fontFamily="Cursive"
              fontStyle="italic"
              textTransform="uppercase"
              fontSize={["lg", "2xl"]}
            >
              GOSURB
            </Text>
          </NavLink>
          <Switch
            id="collapse"
            display={["none", "none", "flex"]}
            isChecked={collapsed}
            onChange={() => setCollapsed(!collapsed)}
            label="Collapse"
          />
          <Switch
            // id="collapse"
            display={["flex", "flex", "none"]}
            isChecked={toggled}
            onChange={() => setToggled(!toggled)}
            // label="Collapse"
          />
        </Box>

        <Text
          color={"white"}
          fontFamily="Times New Roman"
          textTransform="uppercase"
          fontSize={["lg", "2xl"]}
        >
          Welcome
        </Text>
        <Image
          src="/assets/logout.svg"
          alt="dashboard svg"
          h={"40px"}
          w={"40px"}
          m={2}
          cursor={"pointer"}
          onClick={handlelogout}
        ></Image>
      </Box>
      <Flex minH="92.3vh" direction={rtl ? "row-reverse" : "row"} bgColor={"white"}>
        <Sidebar
          rtl={rtl}
          collapsed={collapsed}
          toggled={toggled}
          backgroundColor="#1B2130"
          breakPoint="md"
          onBackdropClick={() => setToggled((prev) => !prev)}
        >
          <Box display={"flex"} justifyContent={"center"} mt={4}></Box>
          <Menu
            menuItemStyles={{
              button: {
                "&:hover": {
                  backgroundColor: "black !important",
                },
              },
            }}
          >
            <MenuItem>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  // backgroundColor:pathname==='/users'?"black":''
                }}
              >
                <Image
                  src="/assets/profile.svg"
                  alt="profile svg"
                  h={"40px"}
                  w={"40px"}
                ></Image>
                <Text fontSize={"large"} color={"white"} fontWeight={"bold"}>
                  My Profile
                </Text>
              </button>
            </MenuItem>

            <MenuItem>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Image
                  src="/assets/dashboard.svg"
                  alt="dashboard svg"
                  h={"40px"}
                  w={"40px"}
                ></Image>
                <Text fontSize={"large"} color={"white"} fontWeight={"bold"}>
                  Dashboard
                </Text>
              </button>
            </MenuItem>
            <MenuItem
              style={{
                backgroundColor: pathname === "/admin/contacts" ? "black" : "",
              }}
            >
              <NavLink to={"/admin/contacts"}>
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Image
                  src="/assets/user.svg"
                  alt="user svg"
                  h={"40px"}
                  w={"40px"}
                ></Image>
                <Text fontSize={"large"} color={"white"} fontWeight={"bold"}>
                  Contacts
                </Text>
              </button>
              </NavLink>
              
            </MenuItem>
         
          </Menu>
        </Sidebar>
{pathname === "/admin/contacts"?<AdminContact></AdminContact>:''}
{/* {pathname === "/posts"?<Posts></Posts>:''}
{pathname === "/reports"?<Reports></Reports>:''} */}
        
      </Flex>
    </Box>
  );
}
