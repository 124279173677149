import {
  Box,
  UnorderedList,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import { useRef } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";

export default function Header() {
  const btnRef = useRef();
  const location = useLocation();
  const { pathname } = location;
  // console.log(pathname)
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      {" "}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"xs"}
      >
        <DrawerOverlay />
        <DrawerContent 
        // bgGradient="linear(to-br, teal.500, blue.600)"
        >
          <DrawerCloseButton />
          <DrawerHeader >
            <NavLink to={"/"}>
              <Text
                cursor={"pointer"}
                fontFamily={"Times New Roman"}
                fontWeight={"bold"}
                fontSize={"4xl"}
            
                bgGradient="linear(to-r, teal.400, blue.500, purple.600)"
                //  bgGradient="linear(to-r, #d4d4d4, #ffffff, #b0b0b0)"
                backgroundClip="text"
              >
                SURB
              </Text>
            </NavLink>
          </DrawerHeader>

          <DrawerBody>
            <UnorderedList
              fontFamily={"Times New Roman"}
              display={"flex"}
              flexDirection={"column"}
              gap={4}
              color={"black"}
            >
              <NavLink to="/features">
                {" "}
                <Text
                  fontSize={"2xl"}
                  color={pathname === "/features" ? "#0856C0" : "black"}
                  // color={pathname === "/features" ? "white" : "black"}
                >
                  Features
                </Text>
              </NavLink>
              <NavLink to="/about">
                <Text
                  fontSize={"2xl"}
                  color={pathname === "/about" ? "#0856C0" : "black"}
                  // color={pathname === "/about" ? "white" : "black"}
                >
                  About Us
                </Text>
              </NavLink>
              <NavLink to="/blog">
                <Text
                  fontSize={"2xl"}
                  color={pathname === "/blog" ? "#0856C0" : "black"}
                  // color={pathname === "/blog" ? "white" : "black"}
                >
                  Blogs
                </Text>
              </NavLink>
              <NavLink to="/contact">
                {" "}
                <Text
                  fontSize={"2xl"}
                  color={pathname === "/contact" ? "#0856C0" : "black"}
                  // color={pathname === "/contact" ? "white" : "black"}
                >
                  Contact Us
                </Text>
              </NavLink>
            </UnorderedList>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box
        minW={"100%"}
        display={"flex"}
        bgColor={"transparent"}
        style={{ paddingTop: "1%", paddingLeft: "5%", paddingRight: "4%" }}
        // gap={3}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <NavLink to={"/"}>
          <Text
            cursor={"pointer"}
            fontFamily={"Times New Roman"}
            fontWeight={"bold"}
            fontSize={"4xl"}
            bgGradient="linear(to-r, teal.400, blue.500, purple.600)"
            backgroundClip="text"
          >
            SURB
          </Text>
        </NavLink>

        <UnorderedList
          fontFamily={"Times New Roman"}
          display={{ base: "none", md: "flex" }}
          gap={10}
          color={"black"}
          fontWeight={"bold"}
          fontSize={"large"}
        >
          <NavLink to="/features">
            <Text color={pathname === "/features" ? "#0856C0" : "black"}>
              Features
            </Text>
          </NavLink>
          <NavLink to="/about">
            <Text color={pathname === "/about" ? "#0856C0" : "black"}>
              About Us
            </Text>
          </NavLink>
          <NavLink to="/blog">
            <Text color={pathname === "/blog" ? "#0856C0" : "black"}>
              Blogs
            </Text>
          </NavLink>
          <NavLink to="/contact">
            <Text color={pathname === "/contact" ? "#0856C0" : "black"}>
              Contact Us
            </Text>
          </NavLink>
        </UnorderedList>
        <HamburgerIcon
          display={{ base: "flex", md: "none" }}
          m={2}
          h={8}
          w={8}
          cursor={"pointer"}
          ref={btnRef}
          onClick={onOpen}
        ></HamburgerIcon>
        {/* <Image
          src="/assets/BurgerNav.svg"
          height={"60px"}
          display={{ base: "flex", md: "none" }}
          m={2}
          cursor={"pointer"}
          ref={btnRef}
          onClick={onOpen}
        ></Image> */}
      </Box>
    </>
  );
}
