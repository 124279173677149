import { Flex,Box, Heading, Text, OrderedList, UnorderedList, ListItem, Link } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";

import useWhatsAppChat from '../common/useWhatsAppChat'
export default function Terms() {
  useWhatsAppChat()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Flex flexDirection={"column"} minHeight="100vh">
      <Box padding="6%"> 
      <Heading as="h2" size="lg" fontFamily={"Times New Roman"} mb={4}>Terms and Conditions</Heading>
      <OrderedList spacing={4} fontFamily={"Times New Roman"}>
        <ListItem fontSize="lg" fontWeight="bold">
          Introduction
        </ListItem>
        <Text mb={4}>
          Welcome to SURB. An Absolutemens.com Fashion Services Private Limited brand These Terms and Conditions govern your use of our software platform, services, and website. By accessing or using SURB, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these Terms, please do not use our services.
        </Text>
        
        {/* Definitions */}
        <ListItem fontSize="lg" fontWeight="bold">
          Definitions
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>SURB:</strong> Refers to our e-commerce and marketplace software platform.</ListItem>
          <ListItem><strong>User:</strong> Any individual or entity using SURB.</ListItem>
          <ListItem><strong>Vendor:</strong> An individual or entity that lists products or services for sale on SURB.</ListItem>
          <ListItem><strong>Customer:</strong> An individual or entity that purchases products or services from Vendors on SURB.</ListItem>
        </UnorderedList>

        {/* Eligibility */}
        <ListItem fontSize="lg" fontWeight="bold">
          Eligibility
        </ListItem>
        <Text mb={4}>
          To use SURB, you must be at least 18 years old and have the legal capacity to enter into a binding agreement.
        </Text>

        {/* Account Registration */}
        <ListItem fontSize="lg" fontWeight="bold">
          Account Registration
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>User Accounts:</strong> Users must create an account to access certain features of SURB. You agree to provide accurate and complete information during registration.</ListItem>
          <ListItem><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</ListItem>
        </UnorderedList>

        {/* Use of SURB */}
        <ListItem fontSize="lg" fontWeight="bold">
          Use of SURB
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>License:</strong> SURB grants you a limited, non-exclusive, non-transferable, and revocable license to use our platform in accordance with these Terms.</ListItem>
          <ListItem><strong>Prohibited Activities:</strong> You agree not to engage in any activity that may harm SURB, our users, or violate any laws. Prohibited activities include, but are not limited to, fraud, harassment, spamming, and copyright infringement.</ListItem>
        </UnorderedList>

        {/* Vendor Obligations */}
        <ListItem fontSize="lg" fontWeight="bold">
          Vendor Obligations
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>Product Listings:</strong> Vendors are responsible for accurately describing their products and services, including pricing, availability, and any applicable terms and conditions.</ListItem>
          <ListItem><strong>Order Fulfillment:</strong> Vendors must fulfill orders promptly and communicate with customers regarding any delays or issues.</ListItem>
          <ListItem><strong>Compliance:</strong> Vendors must comply with all applicable laws and regulations, including those related to consumer protection, privacy, and data security.</ListItem>
        </UnorderedList>

        {/* Customer Obligations */}
        <ListItem fontSize="lg" fontWeight="bold">
          Customer Obligations
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>Purchases:</strong> Customers agree to pay for all products and services purchased through SURB.</ListItem>
          <ListItem><strong>Disputes:</strong> Customers are responsible for resolving disputes with Vendors. SURB may assist in mediating disputes but is not responsible for any issues arising from transactions between Customers and Vendors.</ListItem>
        </UnorderedList>

        {/* Payment Processing */}
        <ListItem fontSize="lg" fontWeight="bold">
          Payment Processing
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>Payment Gateways:</strong> SURB integrates with third-party payment gateways. By using our platform, you agree to the terms and conditions of these payment processors.</ListItem>
          <ListItem><strong>Fees:</strong> SURB may charge fees for certain services. Any applicable fees will be disclosed to you before you use the service.</ListItem>
        </UnorderedList>

        {/* Intellectual Property */}
        <ListItem fontSize="lg" fontWeight="bold">
          Intellectual Property
        </ListItem>
        <UnorderedList styleType="disc" mb={4}>
          <ListItem><strong>Ownership:</strong> SURB retains all rights, title, and interest in and to our platform, including all intellectual property rights.</ListItem>
          <ListItem><strong>User Content:</strong> By submitting content to SURB, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and distribute your content in connection with our services.</ListItem>
        </UnorderedList>

        {/* Privacy */}
        <ListItem fontSize="lg" fontWeight="bold">
          Privacy
        </ListItem>
        <Text mb={4}>
          Your use of SURB is subject to our Privacy Policy, which explains how we collect, use, and protect your personal information.
        </Text>

        {/* Limitation of Liability */}
        <ListItem fontSize="lg" fontWeight="bold">
          Limitation of Liability
        </ListItem>
        <Text mb={4}>
          SURB is provided "as is" and "as available." We do not warrant that our services will be uninterrupted or error-free. To the fullest extent permitted by law, SURB shall not be liable for any indirect, incidental, or consequential damages arising from your use of our platform.
        </Text>

        {/* Indemnification */}
        <ListItem fontSize="lg" fontWeight="bold">
          Indemnification
        </ListItem>
        <Text mb={4}>
          You agree to indemnify and hold harmless SURB, its affiliates, and its respective officers, directors, employees, and agents from any claims, damages, or expenses arising from your use of our platform or violation of these Terms.
        </Text>

        {/* Changes to Terms */}
        <ListItem fontSize="lg" fontWeight="bold">
          Changes to Terms
        </ListItem>
        <Text mb={4}>
          SURB may modify these Terms at any time. We will notify you of any changes by posting the updated Terms on our website. Your continued use of SURB after the changes become effective constitutes your acceptance of the revised Terms.
        </Text>

        {/* Governing Law */}
        <ListItem fontSize="lg" fontWeight="bold">
          Governing Law
        </ListItem>
        <Text mb={4}>
          These Terms and Conditions are governed by and construed by the laws of Karnataka. Any disputes arising from these Terms will be resolved in the courts of Karnataka.
        </Text>

        {/* Contact Us */}
        <ListItem fontSize="lg" fontWeight="bold">
          Contact Us
        </ListItem>
        <Text>
          If you have any questions or concerns about these Terms and Conditions, please contact us at <Link href="mailto:info@absolutemens.com" color="blue.500">info@absolutemens.com</Link>.
        </Text>
      </OrderedList>
    </Box>
    </Flex>
  );
}
