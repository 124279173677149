import { useTable, useSortBy, usePagination } from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  chakra,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalHeader,
  Spinner,
  ModalBody,
  ModalCloseButton,
  Center,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { useMemo, useState, useEffect } from "react";
import {
  TriangleDownIcon,
  TriangleUpIcon,
  ViewIcon,
  DeleteIcon,
} from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
const headers = [
  { label: "Name", key: "name" },
  { label: "Company Name", key: "company_name" },
  { label: "Phone Number", key: "phone_number" },
  { label: "Nature Of Business", key: "nature_of_business" },
  { label: "Message", key: "message" },
  { label: "Creation Date", key: "createdAt" }
];
export default function AdminContact() {
  const [userdata, setUserdata] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apicall, setApicall] = useState(false);
  const navigate = useNavigate();
  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }

    try {
      const response = await fetch(
        `https://surb-backend.vercel.app/api/v1/user/getallcontacts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await response.json();
      if (res.success === true) {
        setUserdata(res.contacts);
      } else {
        navigate("/admin/login");
      }
      // console.log(res)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchData();
  }, [apicall]);

  const handleDelete = async (rowData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login");
    }
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this contact?"
    );
    if (!confirmDelete) {
      return; // Abort the delete operation if the user cancels
    }
    try {
      const response = await fetch(
        `https://surb-backend.vercel.app/api/v1/user/deletecontact`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: rowData._id,
          }),
        }
      );
      const res = await response.json();
      if (res.success === true) {
        setApicall((prev) => !prev);
      }
else{
  navigate('/admin/login');
}
    } catch (error) {
      console.log(error);
    }
  };

  const data = useMemo(() => userdata, [userdata]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Company name",
        accessor: "company_name",
      },
      {
        Header: "Phone number",
        accessor: "phone_number",
        isNumeric: true,
      },
      {
        Header: "Nature of Business",
        accessor: "nature_of_business",
      },
      {
        Header: "Time",
        accessor: "createdAt",
        Cell: ({ value }) => new Date(value).toLocaleString(), // Format the date
      },
      {
        Header: "Messages",
        accessor: "actions",
        Cell: ({ row }) => (
          <IconButton
            aria-label="Edit"
            color={"blue"}
            icon={<ViewIcon />}
            onClick={() => handleEditClick(row.original)}
          />
        ),
      },
      {
        Header: "Delete",
        accessor: "delete",
        Cell: ({ row }) => (
          <IconButton
            aria-label="Delete"
            color={"red"}
            icon={<DeleteIcon />}
            onClick={() => handleDelete(row.original)}
          />
        ),
      },
    ],
    []
  );

  const handleEditClick = (rowData) => {
    setSelectedRowData(rowData); // Set the selected row's data
    onOpen();
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canNextPage,
    canPreviousPage,
    state: { pageIndex },
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  return (
    <Box
      display={"flex"}
       flex="1"
      flexDirection={"column"}
      width={"100%"}
      // position={"relative"}
      // overflow={"auto"}
      overflowY="auto"
    >
      {loading ? (
        <Center height="100vh">
          <Spinner size="xl" />
        </Center>
      ) : (
        <>
          {" "}
          <Box
            display={"flex"}
            mt={4}
            pr={4}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
             <CSVLink data={userdata} filename="ContactUsers.csv" headers={headers}>
             <Button
             colorScheme="orange"
              // fontFamily={"Times New Roman"}
               fontFamily="Times New Roman"
              // fontFamily="Times New Roman, Times, serif"
            >
              Download CSV
            </Button>
             </CSVLink>
           
          
          </Box>
          <TableContainer mt={4} overflow={"scroll"}>
            <Table {...getTableProps()}>
              <Thead fontFamily="Times New Roman">
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderProps } =
                    headerGroup.getHeaderGroupProps();

                  return (
                    <Tr
                      // {...rest}
                      textAlign={"center"}
                      key={key}
                      {...restHeaderProps}
                    >
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumnProps } =
                          column.getHeaderProps(column.getSortByToggleProps());
                        return (
                          <Th
                            textAlign={"center"}
                            key={key}
                            {...restColumnProps}
                            isNumeric={column.isNumeric}
                          >
                            {column.render("Header")}
                            <chakra.span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              ) : null}
                            </chakra.span>
                          </Th>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  return (
                    <Tr key={key} {...restRowProps} justifyContent={"center"}>
                      {row.cells.map((cell) => {
                        const { key, ...restcellProps } = cell.getCellProps();
                        return (
                          <Td
                            textAlign={"center"}
                            fontFamily="Times New Roman"
                            key={key}
                            paddingY={1}
                            {...restcellProps}
                            isNumeric={cell.column.isNumeric}
                          >
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Box
            display={"flex"}
            mt={4}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
          >
            <Button
              isDisabled={pageIndex === 0}
              onClick={() => gotoPage(0)}
              fontFamily={"Times New Roman"}
            >
              First
            </Button>
            <Button
              isDisabled={!canPreviousPage}
              onClick={previousPage}
              fontFamily={"Times New Roman"}
            >
              Prev
            </Button>
            <Text fontFamily={"Times New Roman"}>
              {pageIndex + 1} of {pageCount}
            </Text>
            <Button
              isDisabled={!canNextPage}
              onClick={nextPage}
              fontFamily={"Times New Roman"}
            >
              Next
            </Button>
            <Button
              isDisabled={pageIndex >= pageCount - 1}
              onClick={() => gotoPage(pageCount - 1)}
              fontFamily={"Times New Roman"}
            >
              Last
            </Button>
          </Box>
        </>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={4}>
            {selectedRowData ? (
              <Box>
                <Box fontFamily="Times New Roman">
                  <strong>Name:</strong> {selectedRowData.name}
                </Box>
                <Box fontFamily="Times New Roman">
                  <strong>Company Name:</strong> {selectedRowData.company_name}
                </Box>
                <Box fontFamily="Times New Roman">
                  <strong>Phone Number:</strong> {selectedRowData.phone_number}
                </Box>
                <Box fontFamily="Times New Roman">
                  <strong>Nature of Business:</strong>{" "}
                  {selectedRowData.nature_of_business}
                </Box>
                <Box fontFamily="Times New Roman">
                  <strong>Messages:</strong> {selectedRowData.message}
                </Box>
                <Box fontFamily="Times New Roman">
                  <strong>Time:</strong>{" "}
                  {new Date(selectedRowData.createdAt).toLocaleString()}
                </Box>
              </Box>
            ) : (
              <Box>No data selected</Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
