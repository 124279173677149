import { Flex, Box, Text } from "@chakra-ui/react";
import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { NavLink } from "react-router-dom";
import useWhatsAppChat from '../common/useWhatsAppChat'
export default function Blogs() {
  useWhatsAppChat();
  return (
    <Flex flexDirection={"column"} minHeight="100vh">
      <Header></Header>
      <Box
        style={{ marginTop: "4%", paddingLeft: "4%", paddingRight: "4%" }}
        flex="1"
      >
        <Box
          borderWidth={"2px"}
          borderColor={"black"}
          padding={6}
          borderRadius={"16px"}
          marginBottom={4}
        >
          <Box marginBottom={2}>
            <Text
              fontSize={"2xl"}
              fontFamily={"Times New Roman"} 
              as={"b"}
              color={"#0856C0"}
              textDecoration={"underline"}
            >
              How to Start a Marketplace Business:
            </Text>
          </Box>
          <Text fontFamily={"Times New Roman"} >
            At SURB, we are dedicated to empowering buisness to thrive in the
            digital marketplace. Our e-commerce and marketplace business
            software is designed to help you quickly start, manage, and grow
            your online store with ease.
            <NavLink to={"/blogsfirst"}>
              {" "}
              <Text textDecor={"underline"} color={"blue"}>
                Read more...
              </Text>
            </NavLink>
          </Text>
        </Box>
        <Box
          borderWidth={"2px"}
          borderColor={"black"}
          padding={6}
          borderRadius={"16px"}
        >
          <Box marginBottom={2}>
            <Text
              fontSize={"2xl"}
              as={"b"}
              color={"#0856C0"}
              textDecoration={"underline"}
              fontFamily={"Times New Roman"} 
            >
              Future of E-commerce: How SURB is Adapting to Industry Changes:
            </Text>
          </Box>
          <Text fontFamily={"Times New Roman"} >
            At SURB, we are dedicated to empowering buisness to thrive in the
            digital marketplace. Our e-commerce and marketplace business
            software is designed to help you quickly start, manage, and grow
            your online store with ease.
            <NavLink to={"/blogssecond"}>
              <Text textDecor={"underline"} color={"blue"}>
                {" "}
                Read more...
              </Text>
            </NavLink>{" "}
          </Text>
        </Box>
      </Box>
      <Footer></Footer>
    </Flex>
  );
}
