import React from 'react'
import Header from '../common/Header'
import { Box, Flex, Text } from '@chakra-ui/react'
import Footer from '../common/Footer'
import { useEffect } from 'react'
import useWhatsAppChat from '../common/useWhatsAppChat'



export default function AboutPage() {
  useWhatsAppChat();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Flex flexDirection={'column'}  minHeight="100vh" >
    <Header></Header>
    <Box style={{marginTop:'4%', paddingLeft:'4%', paddingRight:'4%'}}  flex="1">
        <Box marginBottom={4}>

        <Text as={'b'} fontSize={'2xl'} fontFamily={"Times New Roman"} >Welcome to SURB:</Text>
        </Box>
        <Box marginBottom={4}>

        <Text  fontFamily={"Times New Roman"}>At SURB, we are dedicated to empowering businesses to thrive in the digital marketplace. Our e-commerce and marketplace buisness software is designed to help you quickly start, manage, and grow your online store with ease.</Text>
        </Box>
        <Box marginBottom={4}>

        <Text as={'b'} fontSize={'2xl'} fontFamily={"Times New Roman"}>Our Mission:</Text>
        </Box>
        <Box>

        <Text fontFamily={"Times New Roman"} >Our mission is to provide businesses of all sizes with the tools they need to succed online. Whether you are a small startup or a large enterprise, SURB offers a comprehensive suite of features that cater to your unique buisness needs. We aim to make e-commerce accessible, efficient, and effective for everyone.</Text>
        </Box>
    </Box>
    <Footer></Footer>
    </Flex>
  )
}
