import {
  Box,
  FormControl,
  Text,
  Input,
  Textarea,
  FormErrorMessage,
  Button,
  useToast,
} from "@chakra-ui/react";

import React from "react";

import { useState } from "react";
export default function ContactForm() {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [number, setNumber] = useState("");
  const [business, setBusiness] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [errors, setErrors] = useState({
    name: "",
    message: "",
    number: "",
  });
  const handleSubmit = async () => {
    try {
      let newErrors = {};
      const phoneNumberRegex = /^\d{10}$/;
      if (name === "") {
        newErrors.name = "Enter your name";
      }
      if (number === "") {
        newErrors.number = "Enter your phone number";
      } else if (!phoneNumberRegex.test(number)) {
        newErrors.number = "Phone number must be exactly 10 digits";
      }

      if (message === "") {
        newErrors.message = "Enter message";
      }
      if (
        number === "" ||
        name === "" ||
        message === "" ||
        !phoneNumberRegex.test(number)
      ) {
        setErrors(newErrors);
      } else {
        setLoading(true);
        const response = await fetch(
          `https://surb-backend.vercel.app/api/v1/user/contact`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: name,
              company_name: company,
              phone_number: number,
              nature_of_business: business,
              message: message,
            }),
          }
        );
        setLoading(false);
        const res = await response.json();
        console.log(res);
        if (res.success === true) {
          toast({
            title: "Your Details are saved",
            description: "We will contact you soon",
            position: "top",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          setName("");
          setCompany("");
          setBusiness("");
          setMessage("");
          setNumber("");
        } else {
          setLoading(false);
          setName("");
          setCompany("");
          setBusiness("");
          setMessage("");
          setNumber("");
          toast({
            title: "Please try after some time",
            description: "Error occured",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <Text fontSize={"xl"} fontFamily={"Times New Roman"} as={"b"}>
        Get In Touch
      </Text>
      <Box display={"flex"} flexDirection={"column"} gap={6}>
        <FormControl isInvalid={errors.name}>
          <Input
            type="text"
            bgColor={"white"}
            placeholder="Name*"
            borderColor={"black"}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrors({ ...errors, name: "" });
            }}
          />
          {errors.name ? (
            <FormErrorMessage>{errors.name}</FormErrorMessage>
          ) : (
            ""
          )}
        </FormControl>
        <FormControl>
          <Input
            type="text"
            bgColor={"white"}
            placeholder="Company name"
            borderColor={"black"}
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
          />
        </FormControl>
        <FormControl isInvalid={errors.number}>
          <Input
            type="number"
            bgColor={"white"}
            placeholder="Phone number*"
            borderColor={"black"}
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
              setErrors({ ...errors, number: "" });
            }}
          />
          {errors.number ? (
            <FormErrorMessage>{errors.number}</FormErrorMessage>
          ) : (
            ""
          )}
        </FormControl>
        <FormControl>
          <Input
            type="text"
            bgColor={"white"}
            placeholder="Nature of business"
            borderColor={"black"}
            value={business}
            onChange={(e) => {
              setBusiness(e.target.value);
            }}
          />
        </FormControl>
        <FormControl isInvalid={errors.message}>
          <Textarea
            type="text"
            bgColor={"white"}
            placeholder="Message*"
            borderColor={"black"}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              setErrors({ ...errors, message: "" });
            }}
          ></Textarea>
          {errors.message ? (
            <FormErrorMessage>{errors.message}</FormErrorMessage>
          ) : (
            ""
          )}
        </FormControl>
        <Button
          backgroundColor={"#0856C0"}
          colorScheme="none"
          color={"white"}
          onClick={handleSubmit}
          fontFamily={"Times New Roman"}
          isLoading={loading}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}
