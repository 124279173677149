import { Flex, Box, Text, Image } from "@chakra-ui/react";
import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { useEffect } from "react";
import useWhatsAppChat from '../common/useWhatsAppChat'

const features = [
  {
    name: "Ready to Deploy",
    image: "/assets/launch.png",
    description:
      "SURB enables businesses to start selling online instantly, with no delays.",
  },
  {
    name: "OMS & IMS",
    image: "/assets/software.png",
    description:
      "Efficiently track orders by milestones and manage inventory seamlessly with our dashboard.",
  },
  {
    name: "Customer Behaviour Insights",
    image: "/assets/consumer-behavior.png",
    description:
      "Track wishlists, carts, and checkout info. Manage and monitor inventory with ease.",
  },
  {
    name: "Vendor Management",
    image: "/assets/vendor.png",
    description:
      "Manage multiple sellers and track orders by milestones effortlessly.",
  },
  {
    name: "Customizable",
    image: "assets/customization.png",
    description:
      "Customize SURB to fit your business model. Built with CodeIgniter, PHP, and a simple tech stack. Adaptable for selling products, services, or both.",
  },
  {
    name: "Integrated Payment",
    image: "/assets/agreement.png",
    description: "Accept online and COD payments easily (PhonePe integrated). Enjoy quick deliveries with pre-integrated shipping carriers (Delhivery).",
  },
  {
    name: "One Time Purchase",
    image: "/assets/management.png",
    description: "Pay once with no recurring costs. Enjoy 6 months of free updates and bug fixes.",
  },
];

export default function Features() {
  useWhatsAppChat()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const firstDisclosure = useDisclosure();
  // const secondDisclosure = useDisclosure();
  // const thirdDisclosure = useDisclosure();
  // const fourthDisclosure = useDisclosure();
  // const fifthDisclosure = useDisclosure();
  // const sixthDisclosure = useDisclosure();
  // const seventhDisclosure = useDisclosure();
  return (
    <Flex flexDirection={"column"} minHeight="100vh">
      <Header></Header>
      <Box
        style={{
          marginTop: "4%",
          paddingLeft: "4%",
          paddingRight: "4%",
          marginBottom: "4%",
        }}
        flex="1"
      >
        {/* <Box
          display={"flex"}
          flexDirection={["column", "column", "row", "row"]}
          flexWrap={["nowrap", "nowrap", "wrap", "wrap"]}
          justifyContent={"center"}
          alignItems={"center"}
          gap={6}
        >
          <Box
            display={"flex"}
            width={["90%", "80%", "40%", "40%"]}
        
            flexDirection={"column"}
          >
            <Button
              backgroundColor={"#0856C0"}
              display={"flex"}
              justifyContent={"space-between"}
              colorScheme="none"
              color={"white"}
              padding={3}
              paddingY={6}
              borderRadius={"16px"}
              onClick={firstDisclosure.onToggle}
              cursor={"pointer"}
            >
              <Text> Ready to Deploy</Text>
              {!firstDisclosure.isOpen ? (
                <AddIcon boxSize={4} />
              ) : (
                <MinusIcon boxSize={4} />
              )}
            </Button>
            <Collapse in={firstDisclosure.isOpen} >
              <Box
                padding={3}
                color="white"
                bgColor={"white"}
                rounded="md"
                shadow="md"
              >
                <Text color={"black"}>
                  <strong>Instant Start:</strong> SURB is ready to use
                  immediately, allowing businesses to start selling online
                  without any delay.
                </Text>
              </Box>
            </Collapse>
          </Box>
          <Box
            display={"flex"}
            width={["90%", "80%", "40%", "40%"]}
            flexDirection={"column"}
          >
            <Button
              colorScheme="none"
              backgroundColor={"#0856C0"}
              color={"white"}
              display={"flex"}
              justifyContent={"space-between"}
              padding={3}
              paddingY={6}
              borderRadius={"16px"}
              onClick={secondDisclosure.onToggle}
              cursor={"pointer"}
            >
              <Text>OMS & IMS</Text>
              {!secondDisclosure.isOpen ? (
                <AddIcon boxSize={4} />
              ) : (
                <MinusIcon boxSize={4} />
              )}
            </Button>
            <Collapse in={secondDisclosure.isOpen} >
              <Box
                padding={3}
                color="white"
                bgColor={"white"}
                rounded="md"
                shadow="md"
              >
                <Box marginBottom={3}>
                  <Text color={"black"}>
                    <strong>Efficient Operations:</strong> Easily track and
                    manage orders based on milestones.
                  </Text>
                </Box>
                <Box>
                  <Text color={"black"}>
                    <strong>Optimized Supply:</strong>Maintain the right amount
                    of inventory and easily track them using our dashboard.
                  </Text>
                </Box>
              </Box>
            </Collapse>
          </Box>
          <Box
            display={"flex"}
            width={["90%", "80%", "40%", "40%"]}
            flexDirection={"column"}
          >
            <Button
              colorScheme="none"
              backgroundColor={"#0856C0"}
              display={"flex"}
              justifyContent={"space-between"}
              color={"white"}
              padding={3}
              paddingY={6}
              borderRadius={"16px"}
              onClick={thirdDisclosure.onToggle}
              cursor={"pointer"}
            >
              <Text>Customer Behaviour Insights</Text>
              {!thirdDisclosure.isOpen ? (
                <AddIcon boxSize={4} />
              ) : (
                <MinusIcon boxSize={4} />
              )}
            </Button>

            <Collapse in={thirdDisclosure.isOpen} >
              <Box
                padding={3}
                color="white"
                bgColor={"white"}
                rounded="md"
                shadow="md"
              >
                <Box marginBottom={3}>
                  <Text color={"black"}>
                    <strong>Influence Purchase decision:</strong> Easily track
                    the customer’s wishlist, cart information, and checkout page
                    information.
                  </Text>
                </Box>
                <Box>
                  <Text color={"black"}>
                    <strong>Optimized Supply:</strong>Maintain the right amount
                    of inventory and easily track them using our dashboard.
                  </Text>
                </Box>
              </Box>
            </Collapse>
          </Box>
          <Box
            display={"flex"}
            width={["90%", "80%", "40%", "40%"]}
            flexDirection={"column"}
          >
            <Button
              colorScheme="none"
              backgroundColor={"#0856C0"}
              display={"flex"}
              justifyContent={"space-between"}
              color={"white"}
              padding={3}
              paddingY={6}
              borderRadius={"16px"}
              onClick={fourthDisclosure.onToggle}
              cursor={"pointer"}
            >
              <Text>Vendor Management</Text>
              {!fourthDisclosure.isOpen ? (
                <AddIcon boxSize={4} />
              ) : (
                <MinusIcon boxSize={4} />
              )}
            </Button>
            <Collapse in={fourthDisclosure.isOpen} >
              <Box
                padding={3}
                color="white"
                bgColor={"white"}
                rounded="md"
                shadow="md"
              >
                <Box marginBottom={3}>
                  <Text color={"black"}>
                    <strong>Manage Multiple Sellers:</strong> Easily track and
                    manage orders based on milestones.
                  </Text>
                </Box>
              </Box>
            </Collapse>
          </Box>
          <Box
            display={"flex"}
            width={["90%", "80%", "40%", "40%"]}
            flexDirection={"column"}
          >
            <Button
              colorScheme="none"
              backgroundColor={"#0856C0"}
              color={"white"}
              padding={3}
              display={"flex"}
              justifyContent={"space-between"}
              paddingY={6}
              borderRadius={"16px"}
              onClick={fifthDisclosure.onToggle}
              cursor={"pointer"}
            >
              <Text>Customizable</Text>
              {!fifthDisclosure.isOpen ? (
                <AddIcon boxSize={4} />
              ) : (
                <MinusIcon boxSize={4} />
              )}
            </Button>
            <Collapse in={fifthDisclosure.isOpen} >
              <Box
                padding={3}
                color="white"
                bgColor={"white"}
                rounded="md"
                shadow="md"
              >
                <Box marginBottom={3}>
                  <Text color={"black"}>
                    <strong>Flexible:</strong> Rewrite SURB to match your
                    business needs and revenue model. Our product is based on a
                    simple text stack of Codeigniter, PHP, HTML, CSS, Bootstrap,
                    jQuery, and MySQL.
                  </Text>
                </Box>
                <Box marginBottom={3}>
                  <Text color={"black"}>
                    <strong>Adaptable Features:</strong> Modify the platform to
                    sell products, services, or both.
                  </Text>
                </Box>
              </Box>
            </Collapse>
          </Box>
          <Box
            display={"flex"}
            width={["90%", "80%", "40%", "40%"]}
            flexDirection={"column"}
          >
            <Button
              colorScheme="none"
              backgroundColor={"#0856C0"}
              color={"white"}
              display={"flex"}
              justifyContent={"space-between"}
              padding={3}
              paddingY={6}
              borderRadius={"16px"}
              onClick={sixthDisclosure.onToggle}
              cursor={"pointer"}
            >
              <Text>Integrated Payment and Shipping</Text>
              {!sixthDisclosure.isOpen ? (
                <AddIcon boxSize={4} />
              ) : (
                <MinusIcon boxSize={4} />
              )}
            </Button>
            <Collapse in={sixthDisclosure.isOpen}>
              <Box
                padding={3}
                color="white"
                bgColor={"white"}
                rounded="md"
                shadow="md"
              >
                <Box marginBottom={3}>
                  <Text color={"black"}>
                    <strong>Easy Payments:</strong> Accept various payments both
                    online and COD. (PhonePe pre-integrated)
                  </Text>
                </Box>
                <Box marginBottom={3}>
                  <Text color={"black"}>
                    <strong>Smooth Shipping:</strong> Connect with shipping
                    carriers for quick deliveries. (Delhivery pre-integrated)
                  </Text>
                </Box>
              </Box>
            </Collapse>
          </Box>

          <Box
            display={"flex"}
            width={["90%", "80%", "40%", "40%"]}
            flexDirection={"column"}
          >
            <Button
              colorScheme="none"
              backgroundColor={"#0856C0"}
              color={"white"}
              display={"flex"}
              justifyContent={"space-between"}
              padding={3}
              paddingY={6}
              borderRadius={"16px"}
              onClick={seventhDisclosure.onToggle}
              cursor={"pointer"}
            >
              <Text>One Time Purchase</Text>
              {!seventhDisclosure.isOpen ? (
                <AddIcon boxSize={4} />
              ) : (
                <MinusIcon boxSize={4} />
              )}
            </Button>
            <Collapse in={seventhDisclosure.isOpen}>
              <Box
                padding={3}
                color="white"
                bgColor={"white"}
                rounded="md"
                shadow="md"
              >
                <Box marginBottom={3}>
                  <Text color={"black"}>
                    <strong>Cost-Effective:</strong> Pay once with no recurring
                    cost.
                  </Text>
                </Box>
                <Box marginBottom={3}>
                  <Text color={"black"}>
                    <strong>Seamless Updates:</strong> Get free new feature
                    updates and bug fixes for 6 months.
                  </Text>
                </Box>
              </Box>
            </Collapse>
          </Box>
        </Box>  */}

        <Box
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"4%"}
        >
          {features.map((feature, index) => {
            return (
              <Box
                display={"flex"}
                key={index}
                width={["60%","50%","40%","30%"]}
                flexDirection={"column"}
                justifyContent={"center"}
                marginBottom={6}
                alignItems={"center"}
                sx={{
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
                cursor={'pointer'}
              >
                <Image
                  src={feature.image}
                  height={"128px"}
                  width={"128px"}
                ></Image>
                <Text
                  fontFamily={"Times New Roman"}
                  as={"b"}
                  textAlign={"center"}
                >
                  {feature.name}
                </Text>
                <Text
                  fontFamily={"Times New Roman"}
                  // as={"b"}
                  textAlign={"center"}
                >
                  {feature.description}
                </Text>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Footer></Footer>
    </Flex>
  );
}
